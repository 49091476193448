<template>
    <div class="contents">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-main user-member justify-content-sm-between">
                        <div class="d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                            <div class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                <h4 class="text-capitalize fw-500 breadcrumb-title">
                                    Detail Chat Widget
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div v-if="isLoading" class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                        <center>
                            <div class="spinner-border spinner" role="status"></div>
                        </center>
                    </div>
                    <div v-else class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                        <h5>Embed Script</h5>
                        <br>
                        <highlightjs html :code="finalCode"/>
                        <br>
                        <button class="btn btn-secondary btn-block" v-on:click="copyEmbed">Salin Kode</button>
                        <a class="btn btn-primary btn-block" :href="previewURL" target="_blank">Preview</a>
                        <br>
                        <b><i>*Waktu propagasi update adalah 1 jam, simpan ulang chat widget untuk merefresh paksa avatar / nama cs</i></b>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div v-if="isLoading" class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                        <center>
                            <div class="spinner-border spinner" role="status"></div>
                        </center>
                    </div>
                    <div v-else class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                        <div>
                            <center>
                                <h4>Edit Chat Widget</h4>
                            </center>
                            <br>
                            <form v-on:submit.prevent="updateChatWidget" enctype="multipart/form-data">
                                <center><b>Detail Widget</b></center>
                                <br>
                                <label for="">Judul Chat Widget</label>
                                <input type="text" class="form-control" v-model="form.title" required>
                                <br>
                                <label for="">Deskripsi Chat Widget</label>
                                <input type="text" class="form-control" v-model="form.description" required>
                                <br>
                                <label for="">Domain</label>
                                <input type="text" class="form-control" v-model="form.domain" placeholder="www.example.com" required>
                                <br>
                                <label for="">Tipe</label>
                                <select v-model="form.type" class="form-control">
                                    <option value="" selected disabled>== PILIH TIPE ==</option>
                                    <option value="direct">DIRECT</option>
                                    <option value="lander">LANDER</option>
                                </select>
                                <br>
                                <div v-if="form.type == 'lander'">
                                    <div class="card">
                                        <div class="card-body">
                                            <center><b>Pengaturan Lander</b></center>
                                            <br>
                                            <label for="">Tracking Option</label>
                                            <select v-model="form.tracking_option" class="form-control">
                                                <option value="" selected disabled>== PILIH TRACKING OPTION ==</option>
                                                <option value="fb_pixel">Facebook Pixel</option>
                                                <option value="google_tag">Google Tag Manager</option>
                                            </select>
                                            <div v-if="form.tracking_option == 'fb_pixel'">
                                                <br>
                                                <label for="">Pixel ID</label>
                                                <input type="text" class="form-control" v-model="form.fb_track">
                                            </div>
                                            <div v-else-if="form.tracking_option == 'google_tag'">
                                                <br>
                                                <label for="">Google Tag ID</label>
                                                <input type="text" class="form-control" v-model="form.google_track">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <center><b>Style Widget</b></center>
                                <br>
                                <label for="">Gaya Widget</label>
                                <select v-model="form.widget_style" class="form-control">
                                    <option value="1">Judul + Deskripsi + Logo Whatsapp</option>
                                    <option value="2">Logo Whatsapp</option>
                                </select>
                                <br>
                                <label for="">Posisi Widget</label>
                                <select v-model="form.widget_position" class="form-control">
                                    <option value="left">Kiri Layar</option>
                                    <option value="right">Kanan Layar</option>
                                </select>
                                <br>
                                <label for="">Warna Widget</label>
                                <input type="color" class="form-control" v-model="form.widget_bg_color" required>
                                <br>
                                <label for="">Warna Font</label>
                                <input type="color" class="form-control" v-model="form.widget_text_color" required>
                                <br>
                                <hr>
                                <center><b>List Customer Service</b></center>
                                <br>
                                <button type="button" v-on:click="addCS" class="btn btn-primary btn-sm btn-block">Tambah CS</button>
                                <div v-for="(cs_list, index) in form.cs" :key="index">
                                    <br>
                                    <label for="">Pilih Customer Service {{ index + 1 }}</label>
                                    <br>
                                    <select v-model="form.cs[index]" class="form-control" style="max-width: 85%;display: inline-block;">
                                        <option :value="{}" selected disabled>== PILIH CUSTOMER SERVICE ==</option>
                                        <option v-for="customer_service in cs" v-bind:key="customer_service.id" :value="customer_service">{{ customer_service.name }} ({{ customer_service.phone }})</option>
                                    </select>
                                    <button type="button" v-on:click="deleteCS(index)" class="btn btn-sm btn-danger btn-block" style="max-width: 15%;display: inline-block;">Hapus</button>
                                </div>
                               
                                <hr>
                                <center><b>Opsi Lainnya</b></center>
                                <br>
                                <label for="">Buka Otomatis Chat Widget (detik)</label>
                                <input type="number" v-model="form.auto_open" class="form-control" required>
                                <br>
                                <i>Isi dengan 0 jika tidak ingin membuka chat widget secara otomatis</i>
                                <br>
                                <br>
                                <label for="">Tipe Mulai Chat</label>
                                <select v-model="form.starting_chat" class="form-control" required>
                                    <option value="" selected disabled>== PILIH TIPE MULAI CHAT ==</option>
                                    <option value="reply">RELPY MODE</option>
                                    <option value="direct">DIRECT MODE</option>
                                </select>
                                <div v-if="form.starting_chat == 'direct'">
                                    <br>
                                    <label for="">Pesan Mulai Chat</label>
                                    <CustomTextArea v-model="form.message"></CustomTextArea>
                                </div>
                                <br>
                                <button class="btn btn-success btn-block">Simpan</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>


<script>
    //import moment from 'moment'
    import CustomTextArea from "@/components/module/CustomTextarea.vue";

    export default {
        components: {
            CustomTextArea
        },
        data() {
            return {
                form: {
                    title: "",
                    description: "",
                    domain: "",
                    type: "",
                    widget_style: "1",
                    widget_position: "right",
                    widget_bg_color: "#3ebfa5",
                    widget_text_color: "#ffffff",
                    auto_open: "0",
                    cs: [],
                    tracking_option: "",
                    fb_track: "",
                    google_track: "",
                    starting_chat: "",
                    message: "",
                    key: "",
                },
                cs: [],
                finalCode: ``,
                previewURL: '',
                isLoading: true
            };
        },
        async mounted() {
            await this.getCS()
            await this.getChatWidget()

            this.previewURL = process.env.VUE_APP_FRONTEND_URL + '/static/preview_chat_widget.html?key=' + this.form.key

            const base_url = process.env.VUE_APP_FRONTEND_URL
            this.finalCode += '<script async data-name="starsender-widget" data-apikey="'+ this.form.key +'" src="' + base_url +'/cdn/starsender-widget.js?version=1.0.0"><' + '/script> \n'
            this.finalCode += '<link rel="stylesheet" href="' + base_url +'/cdn/starsender-widget.css?version=1.0.0">'
            this.isLoading = false
        },
        methods: {
            copyEmbed(){
                navigator.clipboard.writeText(this.finalCode)
                this.$toast.success("Kode berhasil di copy")
            },
            async getCS(param = {}) {
                let res = await this.$store.dispatch("customer_service_dropdown_list", param);
                this.cs = res.data.data.customer_services
            },
            async getChatWidget() {
                let res = await this.$store.dispatch("chat_widget_detail", { id: this.$route.query.id });
                let chat_widget = res.data.data.chat_widget
                let cs = res.data.data.chat_widget_cs
                this.form = {
                    title: chat_widget.title,
                    description: chat_widget.description,
                    domain: chat_widget.domain,
                    type: chat_widget.type,
                    widget_style: chat_widget.widget_style,
                    widget_position: chat_widget.widget_position,
                    widget_bg_color: chat_widget.widget_bg_color,
                    widget_text_color: chat_widget.widget_text_color,
                    auto_open: chat_widget.auto_open,
                    cs: [],
                    tracking_option: chat_widget.tracking_option,
                    fb_track: chat_widget.fb_track,
                    google_track: chat_widget.google_track,
                    starting_chat: chat_widget.message == "" ? "reply" : "direct",
                    message: chat_widget.message,
                    key: chat_widget.key,
                }

                for(let i = 0;i < cs.length;i++){
                    for(let j = 0;j < this.cs.length;j++){
                        if(this.cs[j].id == cs[i].customer_service_id){
                            this.form.cs.push(this.cs[j])
                        }
                    }
                }
            },
            async addCS(){
                this.form.cs.push({})
            },
            async deleteCS(index){
                this.form.cs.splice(index, 1)
            },
            async updateChatWidget(){
                try{
                    this.form.id = this.$route.query.id
                    let create_chat_widget = await this.$store.dispatch('chat_widget_update', this.form)
                    this.$toast.success(create_chat_widget.data.message)
                }catch(err){
                    this.$toast.error(err.response.data.message);
                }
            }
        },
    };
</script>

<style scoped>
    

    hr{
        margin-top: 15px;
        margin-bottom: 15px;
    }
</style>